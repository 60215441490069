@charset "utf-8";

.finder-block {
  width: 100%;

  .row-search {

    .input-group {

      z-index: 20;
      font-size: 0.9em;

      //Znacznik filtracji
      //Znacznik wyszukaj
      > .input-group-append,
      > .input-group-prepend {

        > span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
          z-index: 4;
          align-items: center;
          color: color(light-gray);
          border-radius: 8px;
          border: none;

          .navigation-item {
            border-radius: 8px;
            border: none;
          }

        }
      }

      //Znacznik filtracji - dodatkowe ustawienia
      > .input-group-prepend {
        > span {
          left: 3px;

          .dropdown-menu {
            min-width: 300px;
            border-radius: 0 16px 16px 16px;
          }

          .navigation-item {
            background-color: shade(silver, 5);
          }
        }
      }

      //Znacznik wyszukaj - dodatkowe ustawienia
      > .input-group-append {
        > span {
          right: 3px;

          .navigation-item {
            background-color: color(blue);
          }
        }
      }

      //input.form-control[type="text"] {
      //    font-size: 0.9rem;
      //    border-right-width: 0;
      //
      //    &.amount-range {
      //        color: color(row-search);
      //        text-align: center;
      //        cursor: help;
      //    }
      //
      //    &#amount-max {
      //        border-right-width: 1px;
      //    }
      //}
      //input.form-control[type="number"] {
      //    font-size: 0.9rem;
      //    border-right: none;
      //    -webkit-appearance: none; /* Webkit */
      //    -moz-appearance: none; /* Mozilla */
      //    -o-appearance: none; /* Opera */
      //    -ms-appearance: none; /* Internet Explorer */
      //    appearance: none; /* CSS3 */
      //}
      //
      //#slider-range {
      //    background-color: color(white-very-light);
      //    border: none;
      //    border-radius: 6px;
      //
      //    div {
      //        background-color: color(blue);
      //        border: 1px solid color(blue);
      //    }
      //
      //    span {
      //        border-radius: 50%;
      //        border: 5px solid color(blue);
      //    }
      //}

      .input-group-text {
        font-size: 0.9rem;
        background-color: white;

        //&.currency-label {
        //    padding-left: 1px;
        //    padding-right: 1px;
        //    text-align: center;
        //    border-radius: 0.25rem 0 0 0.25rem;
        //}

        &.form-control {
          font-size: 0.9rem;
          border-color: color(primary);

          //&::placeholder {
          //    color: tint(row-search, 30);
          //}
          //
          //&:focus {
          //    box-shadow: shadow(search-box);
          //}
        }
      }


      input.form-control[name="phrase"] {

        @include respond-to(big) {
          padding-left: 0.25rem;
          margin-left: -10px;
        }
      }

      //.custom-select {
      //    font-size: 0.9rem;
      //    line-height: 1.75;
      //    color: shade(row-search, 10);
      //    background-image: none;
      //    border-color: shade(row-search-border, 5);
      //    -webkit-appearance: none; /* Webkit */
      //    -moz-appearance: none; /* Mozilla */
      //    -o-appearance: none; /* Opera */
      //    -ms-appearance: none; /* Internet Explorer */
      //    appearance: none; /* CSS3 */
      //
      //    &:focus {
      //        box-shadow: shadow(search-box);
      //    }
      //
      //    option {
      //
      //        &:hover {
      //            color: tint(row-search, 90);
      //            background-color: color(row-search);
      //        }
      //
      //        &.placeholder {
      //            color: tint(row-search, 50);
      //        }
      //    }
      //}
      //
      //#slider-range {
      //    width: 100%;
      //}
    }
  }
}
