@import 'fonts/fonts';

@import 'base/girds', // xs:0, sm:576px (small), md:768px (medium), lg:992px (large), xl:1200px (big)
'base/colors', // color(<key>) | tint(<key>, <percent>) [rozjaśnienie] | shade(<key>, <percent>)
'base/gradients', // gradient(<key>)
'base/shadows', // shadow(<key>)
'base/position';

@import
'modules/finder';

main {

  .main-content {

      // Header
      .header {

        .left-header {

          .location {
            font-size: 12px;
            color: #f44e66;
            font-weight: 600;

            &.primary {
              color: #f44e66;
            }

            &.secondary {
              background: linear-gradient(to right, color(orange), color(purple));
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        .right-header {
          .thumb-resource{
            border-radius: 18px 18px 18px 0 !important;
          }
        }

        .logo {
          width: 100px;
        }

      }

      // Description
      .description {

        .title {
          font-size: 30px;
          font-weight: 600;
          color: #282828;
        }

        .card {
          border: none;
          border-radius: 24px;

          &.card-shadow {
            box-shadow: shadow(card);
          }

        }
      }

      // Calendly
      .calendly {
        //.dropdown-menu {
        //  left: -25ch !important;
        //  min-width: 300px;
        //  border-radius: 16px 0 16px 16px;
        //  z-index: 30;
        //
        //}
      }

      // Benefits
      .benefits {

        .card {
          border: none;
          border-radius: 24px;
        }

        .benefits-subtitle {
          font-weight: 500;
          font-size: 14px;
          color: color(gray-dark);
        }

        .benefits-list {
          gap: 15px 0;

          .benefits-field {
            height: 100%;
            position: relative;
            font-weight: 500;

            .benefits-field-value {
              font-weight: 500;
              font-size: 14px;
              color: color(gray-dark);
            }

            &::before {
              content:"";
              position:absolute;
              top:0;
              left:0;
              right:0;
              bottom:0;
              border-radius: 10px;
              padding: 2px;
              background:linear-gradient(180deg, color(purple), color(orange));
              -webkit-mask:
                      linear-gradient(#fff 0 0) content-box,
                      linear-gradient(#fff 0 0);
              -webkit-mask-composite: destination-out;
              mask-composite: exclude;
            }
          }
        }
      }

      // Oferta
      .offer {

        content: '1';

        .offer-content {
          content: '2';

          .offer-list {
            content: '3';

            .offer-item {
              content: '4';

              .offer-item-banner {
                content: '5';

                .front-data {
                  font-size: 13px;
                  color: color(blue);
                  font-weight: bolder;
                  content: '6';

                  .more-content {
                    content: '7';

                    background-color: #3592FF;
                    border-radius: 4px;
                    width: 22px;
                    height: 22px;
                    font-weight: 500;
                    color: white;
                    font-size: 10px;
                    line-height: 22px;
                  }
                }
              }


              .offer-item-body {

                .offer-item-fields {

                  .offer-item-field-list {
                    gap: 12px;

                    .offer-topic-field {
                      height: 100%;
                      position: relative;
                      font-weight: 500;

                      &.style-1 {
                        border: 1px solid;
                        border-radius: 10px;
                      }

                      &.style-2 {
                        //border: 1px solid;
                        //border-radius: 10px;

                        &::before {
                          content:"";
                          position:absolute;
                          top:0;
                          left:0;
                          right:0;
                          bottom:0;
                          border-radius: 10px;
                          padding: 2px;
                          background:linear-gradient(45deg, color(orange), color(purple));
                          -webkit-mask:
                                  linear-gradient(#fff 0 0) content-box,
                                  linear-gradient(#fff 0 0);
                          -webkit-mask-composite: destination-out;
                          mask-composite: exclude;
                        }
                      }
                    }
                  }
                }

                .offer-item-files {
                  .offer-item-file {
                    .card {
                      border: none;
                      border-radius: 12px;
                      background-color: #f7f7f7;

                      .document-icon {
                        position: relative;
                        background-color: white;
                        width: 60px;
                        display: block;
                        height: 60px;
                        margin: 10px;
                        border-radius: 12px;

                        img {
                          width: 48px;
                          height: 48px;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                        }
                      }
                    }
                  }
                }

                .offer-item-data {
                  font: font(text);
                  font-size: smaller;

                  @include respond-to(small) {
                    font: font(text);
                  }

                  .table-striped {
                    font-weight: 400;

                    &.table-responsive {

                      @include respond-to(small) {
                        width: 100%;
                      }
                    }

                    tbody {
                      tr {
                        border: none;
                        background-color: color(none);

                        td {
                          margin: 0;
                          padding: 0;
                          border: 0;

                          div {
                            padding: 0.35rem 0.5rem;
                            white-space: nowrap;
                            margin: 0;
                            color: color(6);
                            font: inherit;
                            border: none;
                          }

                          &:first-of-type {
                            div {
                              border-radius: 8px 0 0 8px;
                            }
                          }

                          &:last-of-type {
                            div {
                              border-radius: 0 8px 8px 0;
                            }
                          }
                        }

                        &:nth-of-type(2n+1) {
                          td {
                            div {
                              background-color: color(silver);
                            }
                          }
                        }

                        &:nth-of-type(2n) {
                          td {
                            div {
                              background-color: color(white);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .offer-department {

        .offer-courses {

          .offer-course {

            .offer-course-title {

              .subtitle,
              .subtitle {
                font-size: 13px;
                color: color(blue);
                font-weight: bolder;


                .h-5 {
                  margin: 0 auto 1.5em;;

                  @include respond-to(small) {
                    margin: -2px auto 2px;
                  }
                }
              }

              button.display-course {
                border-radius: 7px;
                background-color: color(red);
                border: none;
                font-size: 20px;
                line-height: 20px;
                padding: 8px 10px;
                margin: -5px -15px 5px;

                @include respond-to(large) {
                  margin-left: 0;
                  margin-right: 0;
                }

                .closed {
                  display: block;
                }

                .opened {
                  display: none;
                }

                &[aria-expanded="true"] {
                  background-color: color(dark-silver);

                  .closed {
                    display: none;
                  }

                  .opened {
                    display: block;
                  }
                }
              }
            }

            .offer-course-body {
              line-height: 1.7;
              padding: 10px 15px;

              .recruitment-info {
                color: color(text);
                font-size: 12px;
                text-align: right;
                margin: 10px 10px 20px;
              }

              .offer-course-description {
                font-size: smaller;
                color: color(text);

                p {
                  margin-bottom: 8px;
                  text-align: left;

                  @include respond-to(small) {
                    text-align: justify;
                  }
                }

                @include respond-to(small) {
                  font: font(text);
                }
              }

              .offer-course-specyfication {
                font: font(text);
                font-size: smaller;

                @include respond-to(small) {
                  font: font(text);
                }

                .table-striped {
                  font-weight: 400;

                  &.table-responsive {

                    @include respond-to(small) {
                      width: 100%;
                    }
                  }

                  tbody {

                    tr {
                      border: none;
                      background-color: color(none);


                      td {
                        margin: 0;
                        padding: 0;
                        border: 0;

                        div {
                          padding: 0.35rem 0.5rem;
                          white-space: nowrap;
                          margin: 0;
                          color: color(6);
                          font: inherit;
                          border: none;

                        }

                        &:first-of-type {

                          div {
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                          }
                        }

                        &:last-of-type {

                          div {
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                          }

                        }
                      }

                      &:nth-of-type(2n+1) {

                        td {

                          div {
                            background-color: color(silver);
                          }
                        }
                      }

                      &:nth-of-type(2n) {

                        td {

                          div {
                            background-color: color(white);
                          }
                        }
                      }
                    }

                  }
                }
              }

              .course-videos-row {

                .course-fields-row {
                  text-align: center;

                  .media-course {

                    &.videos {

                      .ico {
                        display: block;
                        text-align: center;
                        padding: 0;

                        img {
                          max-width: 60px;
                        }

                      }

                      .name {
                        font-size: 12px;
                        line-height: 1.15;
                        padding: 10px 0;
                      }
                    }
                  }
                }
              }

              .course-documents-row {
                text-align: center;

                .doc-ico {
                  display: block;
                  text-align: center;
                  padding: 0;

                  .doc-ico-image {
                    max-width: 60px;
                  }
                }

                .doc-name {
                  font-size: 12px;
                  line-height: 1.15;
                  padding: 10px 0;
                }

                form.admin-action {

                  .input-group {
                    margin: 10px 0;
                  }
                }
              }

              .offer-study-fields {
                font-size: 14px;

                h5 {
                  font-size: 16px;
                  color: color(text);
                }

                .offer-study-field {
                  display: inline-block;
                  padding: 8px 16px;
                  margin: 4px;
                  font-size: 16px;
                  border: 1px solid #000000;
                  border-radius: 6px;
                  background-color: white;
                  color: #000000;
                  cursor: pointer;
                }
              }
            }

            .modal-course-edit {

              .doc-ico {

                .doc-ico-image {
                  max-width: 60px;
                }
              }
            }
          }
        }
      }
  }
}

// Modal
.modal {

  .modal-dialog {
    width: 90%;
    max-width: 1200px;

  }
}